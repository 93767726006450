import React, { Component } from 'react';
import { Location } from '@reach/router';
import { Link } from 'gatsby';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { faSun, faMoon } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from './Logo';

export class Navigation extends Component {
  state = {
    active: false,
    currentPath: false,
    isFixed: false,
    isMobile: false,
  };

  componentDidMount() {
    this.setState({ currentPath: this.props.location.pathname });

    if (
      this.props.location.pathname.includes('posts') ||
      this.props.location.pathname.includes('services')
    ) {
      this.setState({ isFixed: true });
    } else {
      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener('resize', this.resize.bind(this));
      this.resize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.resize.bind(this));
  }

  handleScroll = () => {
    if (window.pageYOffset > 0) {
      this.setState({ isFixed: true });
    } else {
      this.setState({ isFixed: false });
    }
  };

  resize() {
    this.setState({ isMobile: window.innerWidth <= 767 });
  }

  render() {
    const { subNav, setUseDarkTheme, useDarkTheme } = this.props;
    const NavLink = ({ to, className, children, ...props }) => (
      <Link
        to={to}
        className={`${to === this.state.currentPath ? 'active' : ''} ${className}`}
        {...props}
      >
        {children}
      </Link>
    );

    return (
      <Navbar
        variant={
          this.state.isFixed || this.state.isMobile ? (useDarkTheme ? 'dark' : 'light') : 'dark'
        }
        bg={this.state.isFixed || this.state.isMobile ? (useDarkTheme ? 'dark' : 'white') : ''}
        fixed="top"
        expand="md"
        className={this.state.isFixed || this.state.isMobile ? 'shadow' : ''}
      >
        <Container className="text-uppercase">
          <NavLink to="/" className="navbar-brand mr-4" style={{ width: '40px' }}>
            <Logo />
          </NavLink>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <NavLink className="nav-link" to="/">
                Home
              </NavLink>
              <NavDropdown
                title="Blog"
                id="nav-dropdown-blog"
                active={
                  this.props.location.pathname.includes('blog') ||
                  this.props.location.pathname.includes('post-categories') ||
                  this.props.location.pathname.includes('posts')
                }
              >
                {/* this.props.location.pathname.includes('posts') ||
                  this.props.location.pathname.includes('blog') ||
                  this.props.location.pathname.includes('post-categories') */}
                <NavLink className="dropdown-item" to="/blog/">
                  All Posts
                </NavLink>
                <NavDropdown.Divider />
                {subNav.posts.map((link, index) => (
                  <NavLink className="dropdown-item" to={link.slug} key={`post-${index}`}>
                    {link.title}
                  </NavLink>
                ))}
              </NavDropdown>
              <NavDropdown
                title="Services"
                id="nav-dropdown-services"
                active={
                  this.props.location.pathname.includes('service') ||
                  this.props.location.pathname.includes('service-categories')
                }
              >
                {/* this.props.location.pathname.includes('posts') ||
                  this.props.location.pathname.includes('blog') ||
                this.props.location.pathname.includes('post-categories') */}
                <NavLink className="dropdown-item" to="/service-categories/">
                  All Services
                </NavLink>
                <NavDropdown.Divider />
                {subNav.services.map((link, index) => (
                  <NavLink className="dropdown-item" to={link.slug} key={`service-${index}`}>
                    {link.title}
                  </NavLink>
                ))}
              </NavDropdown>
              <NavLink className="nav-link" to="/about/">
                About
              </NavLink>
              <NavLink className="nav-link" to="/contact/">
                Contact
              </NavLink>
            </Nav>
            <Nav>
              {/* <Button
                variant={`link shadow-none text-left px-0 text-${
                  this.state.isFixed ? (useDarkTheme ? 'white' : 'dark') : 'white'
                }`}
                onClick={() => setUseDarkTheme(!useDarkTheme)}
              >
                <FontAwesomeIcon icon={useDarkTheme ? faMoon : faSun} />
              </Button> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default ({ subNav, setUseDarkTheme, useDarkTheme }) => (
  <Location>
    {(route) => (
      <Navigation
        subNav={subNav}
        setUseDarkTheme={setUseDarkTheme}
        useDarkTheme={useDarkTheme}
        {...route}
      />
    )}
  </Location>
);
