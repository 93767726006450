import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import Meta from './Meta';
import Nav from './Nav';
import Footer from './Footer';
import { GlobalStyle, lightTheme, darkTheme } from '../utils';
import '../styles/index.scss';

export default ({ children, meta, title }) => {
  return (
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          settingsYaml {
            siteTitle
            siteDescription
            googleTrackingId
            socialNetwork {
              icon
              url
            }
            socialMediaCard {
              image
            }
          }
          allPosts: allMarkdownRemark(
            filter: { fields: { contentType: { eq: "postCategories" } } }
            sort: { order: DESC, fields: [frontmatter___date] }
          ) {
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  title
                }
              }
            }
          }
          allServices: allMarkdownRemark(
            filter: { fields: { contentType: { eq: "serviceCategories" } } }
            sort: { order: DESC, fields: [frontmatter___date] }
          ) {
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  title
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const { siteTitle, socialMediaCard, googleTrackingId, socialNetwork } =
          data.settingsYaml || {};
        const subNav = {
          posts: data.allPosts.hasOwnProperty('edges')
            ? data.allPosts.edges.map((post) => {
                return { ...post.node.fields, ...post.node.frontmatter };
              })
            : false,
          services: data.allServices.hasOwnProperty('edges')
            ? data.allServices.edges.map((service) => {
                return { ...service.node.fields, ...service.node.frontmatter };
              })
            : false,
        };

        const [useDarkTheme, setUseDarkTheme] = useState(false);

        return (
          <>
            <Helmet defaultTitle={siteTitle} titleTemplate={`%s | ${siteTitle}`}>
              {title}
              {/* favicons */}
              <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
              <link rel="manifest" href="/site.webmanifest" />
              <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#001a33" />
              <meta name="msapplication-TileColor" content="#ffffff" />
              <meta name="theme-color" content="#ffffff" />
              {/* endFavicons */}
              <link href="https://ucarecdn.com" rel="preconnect" crossOrigin />
              <link rel="dns-prefetch" href="https://ucarecdn.com" />
              {/* Add font link tags here */}
            </Helmet>

            <Meta
              googleTrackingId={googleTrackingId}
              absoluteImageUrl={socialMediaCard && socialMediaCard.image && socialMediaCard.image}
              {...meta}
              {...data.settingsYaml}
            />
            <ThemeProvider theme={useDarkTheme ? darkTheme : lightTheme}>
              <Nav subNav={subNav} setUseDarkTheme={setUseDarkTheme} useDarkTheme={useDarkTheme} />
              <>{children}</>
              <Footer subNav={subNav} socialNetwork={socialNetwork} />

              <GlobalStyle />
            </ThemeProvider>
          </>
        );
      }}
    />
  );
};
