import React from 'react';

const Logo = () => (
  <svg
    className="logo"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 705 704"
  >
    <defs />
    <path
      fillRule="evenodd"
      d="M20.676 103.741c19.187-11.997 38.373-22.949 57.56-32.865l144.733 72.449c15.548 7.786 22.896 25.995 15.568 41.726L89.124 505.472C18.927 405.166-9.548 280.637 3.739 131.866c.84-11.897 6.79-21.774 16.937-28.115v-.01zm223.32-92.64C280.189 3.704 316.382 0 352.566 0c110.63 0 221.259 34.577 331.899 103.741 10.148 6.342 16.097 16.218 16.937 28.115 15.167 169.987-24.155 308.325-117.958 415.032L438.21 468.804c-15.317-8.234-22.816-25.965-15.567-41.725l82.505-179.316c-20.966 13.181-47.981 15.88-72.207 4.828-24.215-11.051-39.803-33.193-43.472-57.604l-95.673 207.929-9.958 21.644c-23.705 51.522-.84 112.8 50.901 136.406l21.736 9.916 124.267 65.968c-35.203 24.053-74.546 45.707-118.058 64.932-6.679 2.957-13.568 2.957-20.246 0-61.579-27.219-114.829-59.277-159.771-96.164l184.696-396.414 9.958-21.645c23.706-51.521.84-112.8-50.9-136.405l-21.737-9.917-60.708-30.146.02.01zM491.08 126.28c31.945 14.575 45.972 52.179 31.335 83.988-14.638 31.809-52.401 45.777-84.335 31.202-31.944-14.576-45.971-52.179-31.344-83.978 14.637-31.81 52.4-45.778 84.344-31.212z"
      clipRule="evenodd"
    />
  </svg>
);

export default Logo