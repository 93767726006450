import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';
import { primaryFont } from './typography';

export const GlobalStyle = createGlobalStyle`
${normalize()}
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body {
  background-color: ${(props) => props.theme.backgroundColor};
  color: ${(props) => props.theme.textColor};
  font-family: ${primaryFont};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.angle-bottom-placeholder {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
}

.angle-top-placeholder {
  position: absolute;
  top: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
}

.angle-bottom {
  padding-bottom: 100px;
  .angle-bottom-placeholder {
    height: 50;
    border-bottom-color: ${(props) => props.theme.backgroundColor};
    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent;
      border-bottom-color: inherit;
      border-width: 0 0 50px 100vw;
    }
  }
}

.angle-top {
  padding-top: 100px;
  .angle-top-placeholder {
    height: 50px;
    border-top-color: ${(props) => props.theme.backgroundColor};

    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent;
      border-top-color: inherit;
      border-width: 50px 100vw 0 0;
    }
  }
}

.angle-top,
.angle-bottom {
  position: relative;
}

@media (min-width: 768px) {
  .angle-top {
    padding-top: 100px;
    .angle-top .angle-top-placeholder {
      height: 100px;
  
      &:after {
        border-top-width: 100px;
      }
    }
  }  

  .angle-bottom {
    padding-bottom: 100px;

    .angle-bottom-placeholder {
      height: 100px;
  
      &:after {
        border-bottom-width: 100px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .angle-top {
    padding-top: 150px;
    .angle-top-placeholder {
      height: 150px;

      &:after {
        border-top-width: 150px;
      }
    }
  }

  .angle-bottom {
    padding-bottom: 150px;
    .angle-bottom-placeholder {
      height: 150px;
  
      &:after {
        border-bottom-width: 150px;
      }
    }
  }
}

.Home ~ .Footer .angle-top-placeholder,
.Services ~ .Footer .angle-top-placeholder {
  border-top-color: ${(props) => props.theme.backgroundColorSecondary};
}
`;
