import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';
import Subscribe from './Subscribe';

const StyledFooter = styled.footer`
  background-image: linear-gradient(
    135deg,
    ${(props) => props.theme.primaryHoverColor},
    ${(props) => props.theme.primaryColor}
  );
  color: ${(props) => props.theme.textColorOnPrimary};
  padding-top: 9rem;
  margin-top: 0rem;
  margin-bottom: -1px; // hot-fix

  a {
    color: ${(props) => props.theme.textColorOnPrimary};
  }

  a:hover {
    color: ${(props) => props.theme.textColorHoverOnPrimary};
  }

  ul {
    list-style-type: none;
  }

  .sub-footer {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

export class Footer extends Component {
  render() {
    const { subNav, socialNetwork } = this.props;
    const NavLink = ({ to, children, ...props }) => (
      <Link to={to} onClick={this.handleLinkClick} {...props}>
        {children}
      </Link>
    );
    const findIcon = (icon) => {
      switch (icon) {
        case 'facebook':
          return faFacebookF;
        case 'twitter':
          return faTwitter;
        case 'instagram':
          return faInstagram;
        case 'linkedin':
          return faLinkedinIn;
        default:
          return null;
      }
    };
    return (
      <StyledFooter className="Footer angle-top">
        <div className="angle-top-placeholder" />
        <section className="footer py-4">
          <div className="container">
            <div className="row">
              <ul className="col-12 d-flex justify-content-md-end">
                {socialNetwork.map((social, index) => (
                  <li className="mr-4" key={`social-${index}`}>
                    <a href={social.url} rel="noopener noreferrer" target="_blank">
                      <FontAwesomeIcon icon={findIcon(social.icon.toLowerCase())} size="2x" />
                    </a>
                  </li>
                ))}
              </ul>
              <ul className="col-sm-12 col-md-2">
                <li>
                  <b className="text-uppercase">Company</b>
                </li>
                <li>
                  <Link to="/about/">About</Link>
                </li>
                <li>
                  <Link to="/contact/">Contact</Link>
                </li>
                <li>
                  <Link to="/terms-of-use/">Terms of use</Link>
                </li>
                <li>
                  <Link to="/privacy-policy/">Privacy Policy</Link>
                </li>
              </ul>
              <ul className="col-sm-12 col-md-3">
                <li>
                  <b className="text-uppercase">Services</b>
                </li>
                {subNav.services.map((link, index) => (
                  <li key={`services-footer-link-${index}`}>
                    <NavLink to={link.slug}>{link.title}</NavLink>
                  </li>
                ))}
              </ul>
              <ul className="col-sm-12 col-md-2">
                <li>
                  <b className="text-uppercase">Blog</b>
                </li>
                {subNav.posts.map((link, index) => (
                  <li key={`posts-footer-link-${index}`}>
                    <NavLink to={link.slug}>{link.title}</NavLink>
                  </li>
                ))}
              </ul>
              <div className="col-sm-12 col-md-5">
                <Subscribe />
              </div>
            </div>
          </div>
        </section>
        <section className="sub-footer py-4">
          <div className="container">
            <div className="row">
              <small className="col-12">
                © Copyright {new Date().getFullYear()} Tactic Lab. All rights reserved. Crafted with
                {'<3'} by{' '}
                <a rel="noopener noreferrer" target="_blank" href="https://aidosmf.dev/">
                  aidosmf
                </a>
              </small>
            </div>
          </div>
        </section>
      </StyledFooter>
    );
  }
}

export default ({ subNav, socialNetwork }) => (
  <Footer subNav={subNav} socialNetwork={socialNetwork} />
);
