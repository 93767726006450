import { blue, neutral, yellow, green, red } from './colors';
import { primaryFont } from './typography';

export const lightTheme = {
  primaryColor: blue[500],
  primaryHoverColor: blue[300],
  primaryActiveColor: blue[200],
  formElementBackground: neutral[100],
  textOnFormElementBackground: neutral[600],
  textColorOnPrimary: neutral[100],
  textColorHoverOnPrimary: neutral[400],
  textColor: neutral[600],
  textColorInverted: neutral[100],
  backgroundColor: neutral[100],
  backgroundColorInverted: neutral[500],
  backgroundColorSecondary: neutral[200],
  primaryFont,
  disabled: neutral[400],
  textOnDisabled: neutral[300],
  textFieldBackground: neutral[200],
  textFieldLabelColor: neutral[500],
  status: {
    warningColor: yellow[100],
    warningColorHover: yellow[200],
    warningColorActive: yellow[300],
    errorColor: red[100],
    errorColorHover: red[200],
    errorColorActive: red[300],
    successColor: green[100],
    successColorHover: green[200],
    successColorActive: green[300],
  },
};

export const darkTheme = {
  primaryColor: blue[500],
  primaryHoverColor: blue[300],
  primaryActiveColor: blue[200],
  formElementBackground: neutral[100],
  textOnFormElementBackground: neutral[600],
  textColorOnPrimary: neutral[100],
  textColorHoverOnPrimary: neutral[400],
  textColor: neutral[100],
  textColorInverted: neutral[600],
  backgroundColor: neutral[500],
  backgroundColorInverted: neutral[100],
  backgroundColorSecondary: neutral[500],
  primaryFont,
  disabled: neutral[400],
  textOnDisabled: neutral[300],
  textFieldBackground: neutral[200],
  textFieldLabelColor: neutral[500],
  status: {
    warningColor: yellow[100],
    warningColorHover: yellow[200],
    warningColorActive: yellow[300],
    errorColor: red[100],
    errorColorHover: red[200],
    errorColorActive: red[300],
    successColor: green[100],
    successColorHover: green[200],
    successColorActive: green[300],
  },
};
