import React from 'react';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

export default class Subscribe extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false, alert: '', alertVariant: 'info' };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => {
        this.setState({ alert: 'You are successfully subscribed!' });
        form.reset();
      })
      .catch((error) => {
        console.log('error from subscribe', error);
        this.setState({
          alert:
            'There is a problem, your message has not been sent, please try contacting us via email',
          alertVariant: 'danger',
        });
      });
  };

  render() {
    return (
      <Form
        name="subsribe"
        method="post"
        action="/subscribe"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
        {this.state.alert && (
          <Alert
            variant={this.state.alertVariant}
            className="position-fixed shadow"
            onClose={() => this.setState({ alert: '' })}
            dismissible
            style={{
              minWidth: '300px',
              top: '100px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            {this.state.alert}
          </Alert>
        )}
        <b className="text-uppercase">Subscribe</b>
        <p className="mb-1">
          Share your email to receive the latest breaking news to your inbox from Tactic Lab
        </p>
        <div className="input-group mb-1">
          <input
            aria-label="subscribe-email"
            aria-describedby="subscribe"
            id="email"
            type="email"
            name="subsribed-email"
            className="form-control"
            required
            placeholder="email@example.com"
            onChange={this.handleChange}
          />
          <div className="input-group-append">
            <button className="btn btn-primary" type="submit" id="button-subscribe">
              Subscribe
            </button>
          </div>
        </div>
        <small>Unsubscribe at any time</small>
      </Form>
    );
  }
}
